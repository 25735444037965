// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth from Firebase Auth
import firebase from "firebase/compat/app";
import "firebase/compat/auth"; // Import Firebase Auth for compat version

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpaX0CLh7aFFI3CC7Sn-MBoxYl_LAUAwk",
  authDomain: "chess-intel.firebaseapp.com",
  projectId: "chess-intel",
  storageBucket: "chess-intel.appspot.com",
  messagingSenderId: "942104053940",
  appId: "1:942104053940:web:cfa69464b88c52da7eafe1",
  measurementId: "G-K9W4QV6887",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and export it
const auth = getAuth(app);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { auth, firebase };

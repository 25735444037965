import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Button,
  Typography,
  Divider,
  IconButton,
  Checkbox,
  InputLabel,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DrawerHeader } from "./App";
import { styled } from "@mui/system";

// Todo: After clicking clear all, api is called twice
const FilterForm = ({ label, value, onChange, options }) => {
  const dropdownOptions = options.options;
  // console.log("Label: ", label);
  // console.log("Dropdown options: ", dropdownOptions);

  const [anySelected, setAnySelected] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const safeValue = Array.isArray(value) ? value : [];

  useEffect(() => {
    setAnySelected(value.length > 0);
  }, [value]);

  useEffect(() => {
    if (isClearing) {
      onChange([]); // Notify parent component
      setIsClearing(false); // Reset the clearing state
    }
  }, [isClearing, onChange]);

  const clearAll = (event) => {
    event.stopPropagation();
    // console.log("Clearing all selections");
    setIsClearing(true);
  };

  const handleChange = (event) => {
    if (isClearing) {
      return; // Do nothing if clearing is in progress
    }
    // console.log("Selected values: ", event.target.value);
    const selectedValues = event.target.value.filter(
      (val) => val !== undefined
    );
    onChange(selectedValues);
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      style={{
        marginBottom: "8px",
        maxHeight: "40px",
        backgroundColor: anySelected ? "#d5e1e4" : "inherit",
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={safeValue}
        label={label}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
      >
        <MenuItem>
          <Button
            disabled={!anySelected}
            onMouseDown={clearAll}
            size="small"
            variant="outlined"
          >
            Clear
          </Button>
        </MenuItem>
        {dropdownOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PhaseButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: '2px 6px',
  fontSize: '0.75rem',
  textTransform: 'none',
  lineHeight: '1.2',
  flexGrow: 0,
  width: '31%',
}));

export const DrawerContent = ({
  state,
  setters,
  filterOptions,
  handleDrawerClose,
}) => {
  const theme = useTheme();
  // console.log("playerDropdownlist", state.playerDropdownList);
  const resetFilters = () => {
    setters.setColor([]);
    setters.setResult([]);
    setters.setPeakAdvantage([]);
    setters.setOpponentPeakAdvantage([]);
    setters.setMistakeType([]);
    setters.setOpponentRating([]);
    setters.setOpponent([]);
    setters.setOpening([]);
    setters.setYear([]);
    setters.setGameType([]);
    setters.setEndgame([]);
    setters.setTimeFilter([]);
    setters.setPhase('');
  };


  const handlePhaseClick = (value) => {
    setters.setPhase(state.phase === value ? 'any' : value);
  };

  const generalFilters = [
    {
      label: "Game Type",
      value: state.gameType,
      onChange: setters.setGameType,
      options: filterOptions.game_type,
    },
    {
      label: "Color",
      value: state.color,
      onChange: setters.setColor,
      options: filterOptions.color,
    },
    {
      label: "Game Result",
      value: state.result,
      onChange: setters.setResult,
      options: filterOptions.result,
    },
    {
      label: "Opponent Rating",
      value: state.opponentRating,
      onChange: setters.setOpponentRating,
      options: filterOptions.opponent_rating,
    },
    {
      label: "Opponent",
      value: state.opponent,
      onChange: setters.setOpponent,
      options: filterOptions.opponent,
    },
    {
      label: "Opening",
      value: state.opening,
      onChange: setters.setOpening,
      options: filterOptions.opening,
    },
    {
      label: "Year",
      value: state.year,
      onChange: setters.setYear,
      options: filterOptions.year,
    },
    {
      label: "Endgame",
      value: state.endgame,
      onChange: setters.setEndgame,
      options: filterOptions.endgame_type,
    }
  ];

  const deepAnalysisFilters = [
    {
      label: "Peak Advantage",
      value: state.peakAdvantage,
      onChange: setters.setPeakAdvantage,
      options: filterOptions.peak_advantage,
    },
    {
      label: "Opp Peak Advantage",
      value: state.opponentPeakAdvantage,
      onChange: setters.setOpponentPeakAdvantage,
      options: filterOptions.opponent_peak_advantage,
    },
    {
      label: "Mistake Type",
      value: state.mistakeType,
      onChange: setters.setMistakeType,
      options: filterOptions.mistake_type,
    },
    {
      label: "Move Times",
      value: state.timeFilter,
      onChange: setters.setTimeFilter,
      options: filterOptions.time_filter,
    },
  ];

  return (
    <div>
      <DrawerHeader theme={theme}>
        <FormControl fullWidth style={{ marginTop: "5px" }}>
          <Select
            value={state.player}
            label={"Player"}
            onChange={(event) => setters.setPlayer(event.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {state.playerDropdownList.map((playerName) => (
              <MenuItem key={playerName} value={playerName}>
                {playerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <div style={{ padding: 16 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Filters</Typography>
          <Button
            variant="contained"
            color="grey"
            style={{ rightalign: "right", marginLeft: "auto" }}
            onClick={resetFilters}
          >
            Reset
          </Button>
        </div>
        {Object.keys(generalFilters).map((filterKey) => (
          <FilterForm
            key={filterKey}
            label={generalFilters[filterKey].label}
            value={generalFilters[filterKey].value}
            onChange={generalFilters[filterKey].onChange}
            options={generalFilters[filterKey]}
          >
            {Object.keys(generalFilters[filterKey]).map((optionKey) => {
              const option = generalFilters[filterKey][optionKey];
              return (
                <MenuItem key={optionKey} value={optionKey}>
                  {`${option.display_text} (${option.count})`}
                </MenuItem>
              );
            })}
          </FilterForm>
        ))}
        <Divider sx={{ my: 1}}/>
        <Typography variant="h7">Deep Filters</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, marginTop: 1 }}>
          {[
            { value: 'opening', label: 'Opening', range: '(1-15)' },
            { value: 'middle', label: 'Middle', range: '(16-30)' },
            { value: 'end', label: 'End', range: '(31+)' },
          ].map((phase) => (
            <PhaseButton
              key={phase.value}
              variant={state.phase === phase.value ? 'contained' : 'outlined'}
              size="small"
              onClick={() => handlePhaseClick(phase.value)}
            >
              {phase.label} <br /> {phase.range}
            </PhaseButton>
          ))}
        </Box>
        {Object.keys(deepAnalysisFilters).map((filterKey) => (
          <FilterForm
            key={filterKey}
            label={deepAnalysisFilters[filterKey].label}
            value={deepAnalysisFilters[filterKey].value}
            onChange={deepAnalysisFilters[filterKey].onChange}
            options={deepAnalysisFilters[filterKey]}
          >
            {Object.keys(deepAnalysisFilters[filterKey]).map((optionKey) => {
              const option = deepAnalysisFilters[filterKey][optionKey];
              return (
                <MenuItem key={optionKey} value={optionKey}>
                  {`${option.display_text} (${option.count})`}
                </MenuItem>
              );
            })}
          </FilterForm>
        ))}
      </div>
    </div>
  );
};

// src/App.js
import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, CssBaseline, IconButton } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import DataTable from "./DataTable";
import { DrawerContent } from "./DrawerContent";
import CircularProgress from "@mui/material/CircularProgress";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// import ChessIntelLogo from process.env.PUBLIC_URL + '/logo-chessintel.png';

const DRAWER_WIDTH = 240;

const myTheme = createTheme({
  typography: {
    fontFamily: "sans-serif",
  },
  palette: {
    primary: {
      main: "#2e6c7b",
    },
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    }),
    ...(!open && {
      width: `100%`,
    }),
  })
);

// Replace with the path to your logo

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#2e6c7b",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: `100%`,
    marginLeft: 0,
  }),
}));

function MyAppBar({ theme, open, handleDrawerOpen, totalGames, player }) {
  return (
    <AppBar theme={theme} open={open} position="fixed">
      <Toolbar sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Showing {totalGames} Games of {player}
        </Typography>

        <IconButton
          edge="end"
          color="inherit"
          sx={{ height: { xs: 56, sm: 64 } }}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo-chessintel.png"}
            alt="Logo"
            style={{ maxHeight: "100%" }}
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

const fetchGamesFromServer = async (
  userEmail,
  player,
  colorList,
  resultList,
  peakAdvantageList,
  opponentPeakAdvantageList,
  mistakeTypeList,
  opponentRatingList,
  opponentList,
  openingList,
  yearList,
  gameTypeList,
  endgameList,
  timeFilter,
  phase
) => {
  // console.log("phase", phase);
  // console.log("player", player);
  const body = {
    user_email: userEmail,
    player_name_list: [player],
    color_list: colorList,
    result_list: resultList,
    peak_advantage_list: peakAdvantageList,
    opponent_peak_advantage_list: opponentPeakAdvantageList,
    mistake_type_list: mistakeTypeList,
    opponent_rating_list: opponentRatingList,
    opponent_list: opponentList,
    opening_list: openingList,
    year_list: yearList,
    game_type_list: gameTypeList,
    endgame_list: endgameList,
    time_filter: timeFilter,
    phase: phase
  };

  const url = `${process.env.REACT_APP_API_BASE_URL}/game-list`;
  
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const json_response = await response.json();
  return json_response.data;
};

const App = () => {
  const [userEmail, setUserEmail] = useState("");
  
  const playerDropdownListMap = {
    "default": ["Ding, Liren"],
    "viditchess94@gmail.com": ["Vidit, Santosh Gujrathi", "Ding, Liren"],
    "loginajinkya@gmail.com": [
      "Vidit, Santosh Gujrathi",
      "Ding, Liren",
      "Gukesh, Dommaraju",
    ],
    "gukeshchess@gmail.com": ["Ding, Liren", "Gukesh, Dommaraju"],
  };

  const [player, setPlayer] = useState('');
  const [playerDropdownList, setPlayerDropdownList] = useState(['']);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // console.log("userEmail", user.email); 
      // console.log("playerDropdownListMap", playerDropdownListMap);
      setUserEmail(user.email);
      if (user.email in playerDropdownListMap) {
        setPlayerDropdownList(playerDropdownListMap[user.email]);
        setPlayer(playerDropdownListMap[user.email][0]);
      } else {
        setPlayerDropdownList(playerDropdownListMap["default"]);
        setPlayer(playerDropdownListMap["default"][0]);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [totalGames, setTotalGames] = useState(0);
  const [color, setColor] = useState([]);
  const [result, setResult] = useState([]);
  const [peakAdvantage, setPeakAdvantage] = useState([]);
  const [opponentPeakAdvantage, setOpponentPeakAdvantage] = useState([]);
  const [mistakeType, setMistakeType] = useState([]);
  const [opponentRating, setOpponentRating] = useState([]);
  const [opponent, setOpponent] = useState([]);
  const [opening, setOpening] = useState([]);
  const [year, setYear] = useState([]);
  const [gameType, setGameType] = useState([]);
  const [endgame, setEndgame] = useState([]);
  const [timeFilter, setTimeFilter] = useState([]);
  const [phase, setPhase] = useState('any');
  const [isLoading, setIsLoading] = useState(true);

  const state = {
    playerDropdownList: playerDropdownList,
    player: player,
    totalGames,
    color,
    result,
    peakAdvantage,
    opponentPeakAdvantage,
    mistakeType,
    opponentRating,
    opponent,
    opening,
    year,
    gameType,
    endgame,
    timeFilter,
    phase
  };

  const setters = {
    setPlayerDropdownList: setPlayerDropdownList,
    setPlayer: setPlayer, 
    setTotalGames,
    setColor,
    setResult,
    setPeakAdvantage,
    setOpponentPeakAdvantage,
    setMistakeType,
    setOpponentRating,
    setOpponent,
    setOpening,
    setYear,
    setGameType,
    setEndgame,
    setTimeFilter,
    setPhase
  };

  const [filterOptions, setFilterOptions] = useState({
    playerDropdownList: playerDropdownList,
    color: [],
    result: [],
    peak_advantage: [],
    opponent_peak_advantage: [],
    mistake_type: [],
    opponent_rating: [],
    opponent: [],
    opening: [],
    year: [],
    game_type: [],
    endgame_type: [],
    time_filter: [],
    phase: ''
  });

  const [allGames, setAllGames] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  useEffect(() => {
    setIsLoading(true);
    if (player === '') {
      return;
    }
    fetchGamesFromServer(
      userEmail,
      player,
      color,
      result,
      peakAdvantage,
      opponentPeakAdvantage,
      mistakeType,
      opponentRating,
      opponent,
      opening,
      year,
      gameType,
      endgame,
      timeFilter,
      phase
    ).then((response) => {
      setAllGames(response.game_list);
      setIsLoading(false);
      setTotalGames(response.total_games);
      const countSummary = response.count_summary;
      const filters = Object.keys(countSummary).reduce((acc, filterKey) => {
        const options = Object.keys(countSummary[filterKey]).map(
          (optionKey) => ({
            value: optionKey,
            label: `${countSummary[filterKey][optionKey].display_text} (${countSummary[filterKey][optionKey].count})`,
          })
        );

        return { ...acc, [filterKey]: options };
      }, {});
      setFilterOptions(filters);
    });
  }, [
    userEmail,
    player,
    color,
    result,
    peakAdvantage,
    opponentPeakAdvantage,
    mistakeType,
    opponentRating,
    opponent,
    opening,
    year,
    gameType,
    endgame,
    timeFilter,
    phase
  ]);

  return (
    <ThemeProvider theme={myTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MyAppBar
          position="fixed"
          theme={theme}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          totalGames={totalGames}
          player={player}
        />
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerContent
            state={state}
            setters={setters}
            filterOptions={filterOptions}
            handleDrawerClose={handleDrawerClose}
          />
        </Drawer>
        <Main theme={theme} open={open}>
          <DrawerHeader />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading ? (
                // Render a CircularProgress if the data is still loading
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                // Render the DataTable if the data has been fetched
                <DataTable allGames={allGames} state={state} userEmail={userEmail} />
              )}
            </Grid>
          </Grid>
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default App;

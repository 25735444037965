import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import { auth } from "./firebase";
import Login from "./Login";
class Main extends React.Component {
  state = { currentUser: null };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <Router>
        <Routes>
          <Route
            path="/login"
            element={currentUser ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/help"
            element={currentUser ? <Navigate to="/help" /> : <Login />}
          />
          <Route
            path="/"
            element={currentUser ? <App /> : <Navigate to="/login" />}
          />
        </Routes>
      </Router>
    );
  }
}

export default Main;

import { Button, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Typography, Box, Chip } from '@mui/material';

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .wrap-cell': {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'hidden',
  },
});

const ViewMoreButton = ({ params, userEmail }) => {
  const [buttonText, setButtonText] = useState('Play');

  const onClick = async () => {
    const gameId = params.row.game_id;
    const url = `${process.env.REACT_APP_API_BASE_URL}/game-url?game_id=${gameId}&user_email=${encodeURIComponent(userEmail)}`;
    
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.data) {
        window.open(data.data, "_blank");
        setButtonText('Opened');
      } else {
        setButtonText('Error');
      }
    } catch (error) {
      console.error('Error fetching game URL:', error);
      setButtonText('Error');
    }
  };

  return <Button onClick={onClick}>{buttonText}</Button>;
};

const BookmarkButton = ({ params, userEmail }) => {
  const [isBookmarked, setIsBookmarked] = useState(params.row.is_bookmarked || false);

  const toggleBookmark = async () => {
    const newBookmarkState = !isBookmarked;
    const gameId = params.row.game_id;
    const url = `${process.env.REACT_APP_API_BASE_URL}/bookmark`; 

    try {
      console.log("userEmail", userEmail);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
          game_id: gameId,
          is_bookmarked: newBookmarkState,
        }),
      });

      if (response.ok) {
        setIsBookmarked(newBookmarkState);
      } else {
        console.error('Failed to update bookmark');
      }
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  return (
    <IconButton onClick={toggleBookmark}>
      {isBookmarked ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
    </IconButton>
  );
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const GameInfoContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(0.5),
  overflowY: 'auto', 
  marginTop: theme.spacing(1),
}));

const OpeningName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const OpeningSequence = styled(Typography)(({ theme }) => ({
  fontSize: '0.85em',
  color: theme.palette.text.secondary,
}));

const MovesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}));

const MovesChip = styled(Chip)(({ theme }) => ({
  height: 24,
  fontSize: '0.75em',
  pointerEvents: 'auto', // Ensure pointer events are correctly handled
  '&:hover': {
    fontWeight: 'bold', // Increase font weight on hover
    zIndex: 1,  // Ensure it is on top
  },
  '& .MuiChip-label': {
    pointerEvents: 'none', // Ensure the label itself does not affect pointer events
  },
}));

const renderGameInfo = (params, userEmail, mistakeType, timeFilter, phase) => {
  const { opening_sequence, opening_name, adv_drop_moves, game_id, high_time_moves } = params.row;
  const minTimeFilter = Math.min(...timeFilter.map(Number));
  const adv_drop_moves_for_phase = adv_drop_moves[phase];
  if (Array.isArray(adv_drop_moves_for_phase)) {
    adv_drop_moves_for_phase.sort((a, b) => b.adv_drop - a.adv_drop);
  }
  const openingName = opening_name || 'N/A';
  const openingSequence = opening_sequence || 'N/A';

  const getTimeMoves = () => {
    if (timeFilter.length === 0) return [];
    const high_time_moves_for_phase = high_time_moves[phase];
    
    const relevantKeys = Object.keys(high_time_moves_for_phase)
      .map(Number)
      .filter(key => key >= minTimeFilter);

    const finalArray = relevantKeys.flatMap(key => high_time_moves_for_phase[key] || []);

    // Sort the final array by the 'move_number' key
    finalArray.sort((a, b) => b.time_spent - a.time_spent);
  
    return finalArray;
  };

  const timeMoves = getTimeMoves();
  
  const handleMoveClick = (plyNo) => async () => {
    console.log("plyNo", plyNo);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/game-url?game_id=${game_id}&user_email=${encodeURIComponent(userEmail)}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch game URL');
      }
  
      const data = await response.json();
      
      if (data.data) {
        const lichessUrl = `${data.data}#${plyNo-1}`;
        window.open(lichessUrl, '_blank');
      } else {
        console.error('No URL returned from the API');
      }
    } catch (error) {
      console.error('Error fetching game URL:', error);
    }
  };

  return (
    <GameInfoContainer>
      <OpeningName variant="subtitle2">{openingName}</OpeningName>
      <OpeningSequence variant="body2">{openingSequence}</OpeningSequence>
      {adv_drop_moves_for_phase && adv_drop_moves_for_phase.length > 0 && mistakeType.length > 0 && (
        <MovesContainer>
          <Typography variant="caption" component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
            Mistakes:
          </Typography>
          {adv_drop_moves_for_phase.map((advDrop, index) => (
            <MovesChip
              key={index}
              label={`${(advDrop.adv_drop / 100).toFixed(1)} drop aft Move ${Math.ceil(advDrop.ply_no / 2)}`}
              onClick={handleMoveClick(advDrop.ply_no)}
              clickable
              color="primary"
              variant="outlined"
              size="small"
            />
          ))}
        </MovesContainer>
      )}
      {timeMoves && timeFilter.length > 0 && (
        <MovesContainer>
          <Typography variant="caption" component="span" sx={{ fontWeight: 'bold', marginRight: 1 }}>
            Moves &gt; {minTimeFilter} Mins:
          </Typography>
          {timeMoves.map((move_info, index) => (
            <MovesChip
              key={index}
              label={`${formatTime(move_info.time_spent)} mins for move ${move_info.move_number}`}
              onClick={handleMoveClick(move_info.ply_number)}
              clickable
              color="primary"
              variant="outlined"
              size="small"
            />
          ))}
        </MovesContainer>
      )}
    </GameInfoContainer>
  );
};


const EventInfoContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1.5),
  gap: theme.spacing(0.5),
}));

const EventName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: '0.9em',
  lineHeight: 1.2,
}));

const EventType = styled(Typography)(({ theme }) => ({
  fontSize: '0.8em',
  color: theme.palette.text.secondary,
  lineHeight: 1.2,
}));

const renderEventInfo = (params) => {
  const { event, event_type } = params.row;
  
  return (
    <EventInfoContainer>
      <EventName variant="body2">{event || 'N/A'}</EventName>
      <EventType variant="caption">({event_type || 'N/A'})</EventType>
    </EventInfoContainer>
  );
};


const columns = (userEmail, mistakeType, timeFilter, phase) => [
  {
    field: 'bookmark',
    headerName: 'Bookmark',
    renderCell: (params) => <BookmarkButton params={params} userEmail={userEmail} />,
    sortable: false,
    flex: 1,
  },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'opponent', headerName: 'Opponent', flex: 2 },
  { field: 'result', headerName: 'Result', flex: 1 },
  {
    field: 'event_info',
    headerName: 'Event Info',
    flex: 1.5,
    renderCell: renderEventInfo,
    cellClassName: 'wrap-cell',
  },
  {
    field: 'game_info',
    headerName: 'Game Info',
    flex: 3.5,
    renderCell: (params) => renderGameInfo(params, userEmail, mistakeType, timeFilter, phase),
  },
  {
    field: 'viewMore',
    headerName: 'View Analysis',
    sortable: false,
    flex: 1.5,
    disableClickEventBubbling: true,
    renderCell: (params) => <ViewMoreButton params={params} userEmail={userEmail} />,
  },
];

const getRowHeight = (params, mistakeType, timeFilter) => {
  // Check if mistakeType has any elements
  if (mistakeType.length > 0 && timeFilter.length > 0) {
    return 140; // Return height 100 if mistakeType has items
  }
  if (mistakeType.length > 0 || timeFilter.length > 0) {
    return 100; // Return height 100 if mistakeType has items
  }
  return 60; // Return height 80 otherwise
};

const DataTable = ({allGames, state, userEmail}) => { 
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const gamesWithBookmark = allGames.map(game => ({
      ...game,
      is_bookmarked: game.is_bookmarked || false
    }));
    setRows(gamesWithBookmark);
  }, [allGames]);

  return (
    <div>
      <StyledDataGrid 
        key={`${state.mistakeType.join(',')}-${state.timeFilter.join(',')}`} // Update key to force remount
        rows={rows} 
        columns={columns(userEmail, state.mistakeType, state.timeFilter, state.phase)} // Pass mistakeType here
        getRowId={(row) => row.game_id}
        autoHeight
        getRowHeight={(params) => getRowHeight(params, state.mistakeType, state.timeFilter)} // Use dynamic height
      />
    </div>
  );
};

export default DataTable;
